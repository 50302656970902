.btn-secondary {
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #ccc;
  --bs-btn-hover-bg: #e6e6e6;
  --bs-btn-hover-border-color: #adadad;
  --bs-btn-color: #333;
  --bs-btn-hover-color: #333;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #ddd;
  --bs-btn-active-color: #212529;
  --bs-btn-active-bg: #e6e6e6;
  --bs-btn-active-border-color: #dfdfdf;
}


.table {
  --bs-table-hover-bg: rgba(0, 0, 0, .075);
  --bs-table-striped-bg: #f9f9f9;
}


.table-success{
  --bs-table-hover-bg: #bfe3bf;
  --bs-table-active-bg: #bfe3bf;
}
.table-info{
  --bs-table-hover-bg: #bce5f2;
  --bs-table-active-bg: #bce5f2;
}
.table-warning{
  --bs-table-hover-bg: #f8dea6;
  --bs-table-active-bg: #f8dea6;
}
.table-danger{
  --bs-table-hover-bg: #efbbb9;
  --bs-table-active-bg: #efbbb9;
}
